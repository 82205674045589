





















































import { Vue, Component } from "vue-property-decorator";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import UpPhoto from "../../Api/UpPhoto/index"
import { SetUserData,GetUserData } from "../../Api/Basics/index"
import Stroage from "../../util/Storage"
import { ImagePreview,Notify } from "vant"
import ZoomPage from "@/impView/PageSafety";

interface UserPhotoType<T=string> {
  SetIcon:T;
  DefaultPhoto:T;
  Show:boolean;
  loadingShow:boolean;
  userId:T|number;
  token:T;
  headImg?:string;
  handleChangeShow():void;
  handleBack?(): void;
  handlePhotoPreView():void;
  handleShow():void;
  handleUpPhoto(e?:any):void;
  handleError(data:string):void;
}

@Component({ name: "UserPhoto",components:{ HeadTop } })
export default class UserPhoto extends ZoomPage implements UserPhotoType{
    SetIcon = require("../../assets/icon/Basics/set.png");
    DefaultPhoto = require("../../assets/icon/Basics/default.png")
    Show = false;
    loadingShow = true;
    userId = ""
    token = ""
    headImg = ""

    mounted() {
        this.loadingShow = false
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        this.headImg = Stroage.GetData_ && JSON.parse( Stroage.GetData_("UserData") ).headImg || this.DefaultPhoto;
    }

    handleChangeShow(){
        this.Show = !this.Show
    }

    handlePhotoPreView(){
        let Img : Array<string> = [];
        if( this.headImg ){
            Img = [ this.headImg ]
        }
        if( Img.length ){
            ImagePreview({
                images:Img,
                startPosition:0
            })
        }
    }

    handleShow(){
        this.handleChangeShow()
    }

    handleUpPhoto(e:any){
        if( !e.file ){
            this.handleError("请选择修改头像")
            return;
        }
        let from = new FormData()
        from.append("functionName","headimg")
        from.append("file",e.file)
        this.loadingShow = true
        new Promise((resolve,reject)=>{
            UpPhoto({
                userId:this.userId,
                token:this.token
            },from).then((res:any)=>{
                console.log( res )
                if( res.message.code == "200" ){
                    resolve( res.data as string )
                }else{
                    reject( res.message.msg )
                }
            })
        }).then((res:any)=>{
            if( !res ){return;}
            return (
                SetUserData({
                    userId:this.userId,
                    token:this.token
                },{
                    headImg:res as string
                }).then( (res:any)=>{
                    console.log(res);
                    if( res.message.code === "200" ){
                        return true
                    }else{
                        this.loadingShow = false
                        this.handleError( res.message.msg )
                    }
                } )
            )
        }).then((res:any)=>{
            if( !Boolean(res) ){ this.loadingShow = false ;return; }
            GetUserData({
                userId:this.userId as string,
                token:this.token as string
            }).then((res:any)=>{
                console.log( res )
                this.loadingShow = false
                this.handleChangeShow()
                if( res.message.code === "200" ){
                    this.headImg  = res.data.headImg || ""
                    Stroage.SetData_ && Stroage.SetData_("UserData",JSON.stringify(res.data))
                }else{
                    this.handleError( res.message.msg )
                }
            })
        }).catch((cat:any)=>{
            this.loadingShow = false
            this.handleError(cat.message.msg)
        })
    }

    handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }
}
